var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-data-table',{attrs:{"items":_vm.items,"search":_vm.search,"headers":_vm.headers,"items-per-page-options":[
                        {value: 5, title: 5},
                        {value: 10, title: 10},
                        {value: 20, title: 20}
                    ]},scopedSlots:_vm._u([{key:"item.submitted",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.submitted)+" of "+_vm._s(item.polls.length)+" responded ")]}},{key:"item.colleagues",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.groupNames)),_c('br')]),_c('span',[_vm._v(_vm._s(item.userNames)),_c('br')])]}},{key:"item.btn2",fn:function(ref){
                    var item = ref.item;
return [_c('edit-poll',_vm._b({on:{"delete":function (v) { return _vm.deleting = v; }}},'edit-poll',item,false))]}}])}),_c('v-text-field',{attrs:{"placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('are-you-sure',{attrs:{"text":"Are you sure you want to delete this poll? ","value":_vm.deleting,"cancel-btn":""},on:{"input":function($event){return _vm.deletePoll()},"cancel":function($event){_vm.deleting = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }