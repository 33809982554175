var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-data-table',{attrs:{"items":_vm.items,"search":_vm.search,"headers":_vm.headers,"items-per-page-options":[
            {value: 5, title: 5},
            {value: 10, title: 10},
            {value: 20, title: 20}
        ]},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(item.status == 'delegated')?_c('v-chip',{staticClass:"mr-3",attrs:{"color":"secondary"}},[_vm._v("Delegated")]):_vm._e(),(item.status == 'not_started')?_c('v-chip',{staticClass:"mr-3",attrs:{"color":"secondary"}},[_vm._v("Not Started")]):_vm._e(),(item.status == 'complete')?_c('v-chip',{staticClass:"mr-3",attrs:{"color":"success"}},[_vm._v("Complete")]):_vm._e(),_c('v-chip',[_vm._v(_vm._s(Math.floor(item.rate))+"%")])]}},{key:"item.btn1",fn:function(ref){
        var item = ref.item;
return [(item.user)?_c('remind-colleague',{attrs:{"icon":""},model:{value:(item.user),callback:function ($$v) {_vm.$set(item, "user", $$v)},expression:"item.user"}}):_vm._e()]}},{key:"item.btn2",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.navigate(item)}}},[_c('v-icon',[_vm._v("mdi-chevron-right-circle")])],1)]}}])}),_c('v-text-field',{attrs:{"placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }