export default {
	firestorePath: "groups",
	firestoreRefType: "collection",
	moduleName: "groups",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {
		forOrganisation(state) {
			return (id) => {
				return Object.values(state.data)
				.filter( a => a.status == 'published')
				.filter( a => a.organisation == id )
			}
		}
	},
	mutations: {},
	actions: {
	},
};
