<template>
	<v-sheet>
    <v-data-table
        :items="items"
        :search="search"
        :headers="headers"
        :items-per-page-options="[
            {value: 5, title: 5},
            {value: 10, title: 10},
            {value: 20, title: 20}
        ]"
    >
			<template v-slot:item.status="{item}">
				<v-chip class="mr-3" v-if="item.status == 'delegated'" color="secondary">Delegated</v-chip>
				<v-chip class="mr-3" v-if="item.status == 'not_started'" color="secondary">Not Started</v-chip>
				<v-chip class="mr-3" v-if="item.status == 'complete'" color="success">Complete</v-chip>

                <v-chip>{{Math.floor(item.rate)}}%</v-chip>
			</template>
			<template v-slot:item.btn1="{item}">
				<remind-colleague v-if="item.user" v-model="item.user" icon></remind-colleague>
            </template>
			<template v-slot:item.btn2="{item}">
                <v-btn icon color="primary" @click="navigate(item)"><v-icon>mdi-chevron-right-circle</v-icon></v-btn>
            </template>
    </v-data-table>
    <v-text-field placeholder="Search" v-model="search"></v-text-field>

	</v-sheet>
</template>

<script>
import RemindColleague from '../collaboration/RemindColleague.vue';
	export default {
		name: "DelegationsList",
		data: () => {
			return {
                search: null
			};
		},
		computed: {
            current() {
                return this.$store.state.current;
            },
            users() {
                return this.$store.state.users.data
            },
            sectionResponses() {
                return this.$store.state.sectionResponses.data
            },
            categoryOptions(){
                return this.$store.state.categoryOptions.data;
            },
            order(){
                const self = this;
                let order = this.$store.getters.order;
                return order.map( item => {
                    let sectionResponse = self.sectionResponses[item.response];
                    if( sectionResponse ){
                        return {
                            ...item,
                            service: sectionResponse.multiple ? sectionResponse.service : false
                        }
                    }
                })
            },
            sections( ){
                return this.$store.state.sections.data
            },
            items(){
                const self = this;
                let items = self.order.map( item => {
                    return {
                        ...item, 
                        sectionName: self.sections[item.section].name,
                        serviceName: item.service ? self.categoryOptions[item.service.name] : "-",
                        status: self.sectionResponses[item.response].status,
                        users: "",
                        rate: self.sectionResponses[item.response].completion_rate
                    }
                })
                items.forEach( item => {
                    if( item.status == "delegated" ){
                        let users = self.sectionResponses[item.response].users;
                        let delegate = Object.keys(users).find( id => users[id] == 'delegate');
                        if( delegate ){
                            item.user = delegate;
                            item.userName = self.users[delegate].name;
                        }
                    }
                })
                return items;
            },
			headers() {
				return [
					{value: "sectionName", text: "Section"},
                    {value: "status", text: "Status"},
                    {value: "userName", text: "Colleague"},
					{value: "btn1"},
					{value: "btn2"}
				];
			}
		},
		methods: {
            navigate({section, response}){
                this.$store.commit("GO_TO", {section, response});
                this.$router.push("/assessment")
            }
		},
        components: {
            RemindColleague
        }

	};
</script>
