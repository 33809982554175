<template>    
    <v-autocomplete 
        :multiple="true"
        chips 
        label="Select Colleague Group" 
        persistent-hint 
        :items="groups"
        item-value="id"
        item-text="name"
        hint="Your groups can be managed on the My Team page"
        @input="value => $emit('input', value )"> 
        <template v-slot:item="{item}">
            {{item.name}} ({{item.members.length}})
        </template>
    </v-autocomplete>
</template>

<script>

export default {
    computed: {
        organisation( ) {
            return this.$store.state.organisation.data
        },
        groups(){
            return this.$store.getters["groups/forOrganisation"]( this.organisation.id )
        },
    }    
}
</script>
