<template>
			<mw-dialog v-if="id"
				:title="`Edit Group | ${group.name}`"
				v-model="dialog"
				
				submit-btn
				@submit="submit"
			>
				<template v-slot:button>
					<v-btn icon @click="dialog = true">
						<v-icon>mdi-pen</v-icon>
					</v-btn>
				</template>
				<template v-slot:dialog-body>
					<v-text-field v-model="group.name" label="Name"></v-text-field>
					<v-textarea v-model="group.description" label="Description"></v-textarea>
					<v-autocomplete label="Group Members" :items="users" multiple chips v-model="group.members"></v-autocomplete>
					<v-textarea v-model="group.notes" label="Notes"></v-textarea>
				</template>
			</mw-dialog>
</template>
<script>
	import MwDialog from "@c/ui/MwDialog.vue";
	export default {
		name: "CreateGroup",
		props: {
			id: String,
		},
		data: () => {
			return {
				dialog: false,
			};
		},
		computed: {
			group() {
				return this.$store.state.groups.data[this.id] 
			},
			users() {
				return Object.values(this.$store.getters["users/respondents"]).map( user => ({text: user.name, value: user.id }))
			},
		},
		methods: {
			submit() {
				this.$store.dispatch("groups/patch", {
					id: this.id,
					name: this.group.name, 
					description: this.group.description,
					members: this.group.members, 
					notes: this.group.notes || null
				}).then( () => {
					this.dialog = false;
				})
			}
		},
		watch: {
		},
		components: {
			MwDialog,
		},
	};
</script>
