export default {
	firestorePath: "organisations/{id}",
	firestoreRefType: "doc",
	moduleName: "organisation",
	statePropName: "data",
	namespaced: true,
	serverChange: {
		modifiedHook: function(updateStore, doc, _, {dispatch, state}) {
			dispatch(
				"assessments/openDBChannel",
				{clauses: {where: [["organisation", "==", doc.id]]}},
				{root: true}
			);
			dispatch(
				"groups/openDBChannel",
				{clauses: {where: [["organisation", "==", doc.id]]}},
				{root: true}
			)
			dispatch("services/fetchAndAdd", false, { root: true });
			dispatch("providers/fetchAndAdd", false, { root: true });
			if (doc.leadership.includes( state.auth.data.id )) {
				dispatch("users/fetchRespondents", doc.id, {root: true});
			}
			return updateStore(doc);
		}
	},
	state: {
		data: {
			leadership: [],
			approvers: []
		}
	},
	getters: {
		id(state) {
			return state.data.id;
		}
	},
	mutations: {},
	actions: {
		start({dispatch, commit}, {id, reset}) {
			if (reset) {
				commit("assessments/RESET_VUEX_EASY_FIRESTORE_STATE", null, {
					root: true
				});
				commit("assessment/RESET_VUEX_EASY_FIRESTORE_STATE", null, {
					root: true
				});
				commit("organisation/RESET_VUEX_EASY_FIRESTORE_STATE", null, {
					root: true
				});
				commit("conference/RESET_VUEX_EASY_FIRESTORE_STATE", null, {
					root: true
				});
				commit("users/RESET_VUEX_EASY_FIRESTORE_STATE", null, {root: true});
				commit("sectionResponses/RESET_VUEX_EASY_FIRESTORE_STATE", null, {
					root: true
				});
				commit("staffSurveyResponses/RESET_VUEX_EASY_FIRESTORE_STATE", null, {
					root: true
				});
				commit("videoRoom/RESET_VUEX_EASY_FIRESTORE_STATE", null, {root: true});
			}
			dispatch("openDBChannel", {pathVariables: {id}});
		}
	}
};
