<template>
    <mw-dialog 
        title="User Polls"
        v-model="dialog"
        cancel-btn
        large
    >
        <template v-slot:button>
            <v-btn icon @click="dialog = true">
                <v-icon>mdi-account-box</v-icon>
            </v-btn>
        </template>
        <template v-slot:dialog-body>
                <v-data-table
                    :items="items"
                    :search="search"
                    :headers="headers"
                    :items-per-page-options="[
                        {value: 5, title: 5},
                        {value: 10, title: 10},
                        {value: 20, title: 20}
                    ]"
                >
					<template v-slot:item.status="{item}"> 
						<v-chip color="success" v-if="item.status == 'submitted'">Submitted</v-chip>
						<v-chip color="secondary" v-if="item.status == 'published'">Outstanding</v-chip>
						<v-chip color="red" v-if="item.status == 'deleted'">Deleted</v-chip>
                    </template>
                </v-data-table>
                            
                            
                <v-text-field placeholder="Search" v-model="search"></v-text-field>
                <v-row>
                    <v-col>
                        Send your colleague a reminder to participate
                    </v-col>
                    <v-col>
                        <remind-colleague v-model="value"></remind-colleague>
                    </v-col>
                </v-row>
        </template>
    </mw-dialog>
</template>
<script>
	import MwDialog from "@c/ui/MwDialog.vue";
	import RemindColleague from '@c/collaboration/RemindColleague.vue';
	export default {
		name: "CollaborationsForUser",
		props: {
			value: String,
		},
		data: () => {
			return {
				dialog: false,
                search: ""
			};
		},
		computed: {
			user() {
				return this.$store.state.users.data[this.value]
			},
            polls() {
                return this.$store.state.polls.data    
            },
            userPolls() {
				const self = this;
				return Object.values(self.polls)
					.filter((poll) => poll.user == self.value)
			},
            sections(){
                return this.$store.state.sections.data
            },
            sectionResponses(){
                return this.$store.state.sectionResponses.data
            },            
            categoryOptions(){
                return this.$store.state.categoryOptions.data;
            },
            items(){
                const self = this;
                let items = self.userPolls;
                items.forEach(item => {
                    let response = self.sectionResponses[item.response];
                    item.quesion = item.question;
                    item.questionText = self.$store.getters["questions/getQuestionTexts"](item.question).string
                    item.section = self.sections[response.section].name;
                    if( response.service ){
                        item.service = self.categoryOptions[item.service].name;
                    }
                });
                return items;
            },

            headers() {
				return [
                    {value: "section", text: "Section"},
                    {value: "service", text: "Service"},
					{value: "questionText", text: "Question"},
                    {value: "status", text: "Status"},
					{value: "btn2"}
				];
			}
		},
		methods: {
		},
		watch: {
            dialog: {
                // immediate: true, 
                handler(v) {
                    if( v ){
                        this.$store.dispatch("polls/byUser", this.value);
                    }
                }
            }
		},
		components: {
			MwDialog,     
			RemindColleague       
		},
	};
</script>
