<template>
	<v-sheet>
        <create-group></create-group>
		<v-data-table
			:search="search"
			:headers="headers"
			:items="groups"
			:items-per-page-options="[
				{value: 5, title: 5},
				{value: 10, title: 10},
				{value: 20, title: 20}
			]"
		>

			<template v-slot:item.members="{item}">
				<v-chip color="success">{{item.members.length}}</v-chip>
			</template>
			<template v-slot:item.btn2="{item}"> 
				<edit-group :id="item.id"></edit-group>
                <v-btn icon @click="deleting = item.id" class="ml-2">
					<v-icon>mdi-trash-can</v-icon>
				</v-btn>
			</template>
			<template v-slot:body.append>
				<tr color="lightest" class="mt-5 lightest--background">
					<td>
						<v-text-field placeholder="Search" v-model="search"></v-text-field>
					</td>
					<td colspan="4">
					</td>
				</tr>
			</template>
		</v-data-table>
		<are-you-sure
			text="Are you sure you want to delete this group? The members of this group will not be affected."
			:value="deleting"
			@input="deleteGroup()"
            @cancel="deleting = null"
            cancel-btn
		></are-you-sure>
	</v-sheet>
</template>

<script>
import CreateGroup from "@c/team/CreateGroup.vue";
import EditGroup from "@c/team/EditGroup.vue";
import AreYouSure from '@c/notices/AreYouSure.vue';
	export default {
		name: "GroupList",
		data: () => {
			return {
				search: "",
				dialog: false,
				expanded: false,
				deleting: false
			};
		},
		computed: {
            organisation() {
                return this.$store.state.organisation.data
            },
			users() {
				return this.$store.getters["users/respondents"];
			},
            groups(){
                return this.$store.getters["groups/forOrganisation"]( this.organisation.id )
            },
			headers() {
				return [
					{value: "name", text: "Name"},
					{value: "description", text: "Description"},
					{value: "members", text: "Members"},
					{value: "btn2"}
				];
			}
		},
		methods: {
			deleteGroup() {
				this.$store.dispatch("groups/patch", {id: this.deleting, status: "deleted"}).then( () => {
					this.deleting = null;
				})
			}
		},
		watch: {
		},
		components: {
			EditGroup,
			CreateGroup,
			AreYouSure
		},
		created() {
		}
	};
</script>
