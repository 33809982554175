<template>
	<v-sheet>
		<p class="text-right">
			<mw-dialog
				v-model="dialog"
				small
				submit-btn
				@submit="dialog = false"
				:title="$t('collaboration.add_colleague')"
			>
				<template v-slot:button>
					<v-btn @click="dialog = true" color="primary">
						{{ $t("collaboration.add_colleague") }}
					</v-btn>
				</template>
				<template v-slot:dialog-body>
					<add-colleague @input="dialog = false"></add-colleague>
				</template>
			</mw-dialog>
			<import-users></import-users>
		</p>
		<v-data-table
			id="address-book"
			ref="address-book"
			:search="search"
			:headers="headers"
			:items="items"
			:items-per-page-options="[
				{value: 5, title: 5},
				{value: 10, title: 10},
				{value: 20, title: 20}
			]"
		>

			<template v-slot:item.btn2="{item}">
				<collaborations-for-user v-if="assessment.id" v-model="item.id"></collaborations-for-user>
				<colleague-details icon v-model="item.id"></colleague-details>
				<remind-colleague v-model="item.id" icon></remind-colleague>
			</template>
			<template v-slot:item.memberships="{item}">
				<v-chip v-for="group in item.memberships" :key="group" class="mr-2">{{group}}</v-chip>
			</template>
			<template v-slot:body.append>
				<tr color="lightest" class="mt-5 lightest--background">
					<td>
						<v-text-field placeholder="Search" v-model="search"></v-text-field>
					</td>
					<td colspan="4">
					</td>
				</tr>
			</template>
		</v-data-table>
	</v-sheet>
</template>


<style lang="less">
	#address-book .text-start {
		vertical-align: top !important;
		padding-top: 10px !important;
	}
</style>
<script>
	import RemindColleague from '../collaboration/RemindColleague.vue';
	import ImportUsers from "@c/collaboration/ImportUsers";
	import AddColleague from "@c/collaboration/AddColleague";
	import ColleagueDetails from "@c/collaboration/ColleagueDetails";
	import MwDialog from "@c/ui/MwDialog.vue";
	import CollaborationsForUser from './CollaborationsForUser.vue';
	export default {
		name: "PeopleList",
		data: () => {
			return {
				search: "",
				dialog: false,
				expanded: false,
			};
		},
		computed: {
			users() {
				return this.$store.getters["users/respondents"];
			},
            organisation() {
                return this.$store.state.organisation.data
            },
            assessment() {
                return this.$store.state.assessment.data
            },
			groups() {
				return Object.values(this.$store.state.groups.data)
			},
			items() {
				const self = this;
				var users = Object.values(self.users);
                users.forEach( user => {
                    user.role = "Team Member";
                    if( self.organisation.approvers.includes(user.id ) ){
                        user.role = "Approver";
                    }
                    if( self.organisation.leadership.includes(user.id ) ){
                        user.role = "Leadership";
                    }
					user.memberships = self.groups.filter( group => group.members.includes(user.id) ).map(group => group.name)
                    return user
                })
				return users;
			},
			headers() {
				return [
					{text: "Name", value: "name"},
					{text: "Role", value: "role"},
					{text: "Memberships", value: "memberships"},
					{value: "btn2", width: "15%"}
				];
			}
		},
		methods: {
		},
		watch: {
		},
		components: {
			MwDialog,
			AddColleague,
			ColleagueDetails,
			ImportUsers, 
			CollaborationsForUser,
			RemindColleague
		},
		created() {
		}
	};
</script>
