var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mw-dialog',{attrs:{"title":"Poll Details","cancel-btn":"","large":""},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]},proxy:true},{key:"dialog-body",fn:function(){return [_c('p',[_vm._v("Poll status by individual users (whether polled individually or as part of a group) is available below. You can also delete polls and send reminders here.")]),_c('v-data-table',{attrs:{"items":_vm.polls,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.group",fn:function(ref){
var item = ref.item;
return [(item.group)?_c('span',[_vm._v(_vm._s(_vm.groups[item.group].name))]):_vm._e()]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.users[item.user].name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'submitted')?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("Submitted")]):_vm._e(),(item.status == 'published')?_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v("Outstanding")]):_vm._e(),(item.status == 'deleted')?_c('v-chip',{attrs:{"color":"red"}},[_vm._v("Deleted")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mwutils.toReadableDate(item.created_at))+" ")]}},{key:"item.btn2",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete', item.id)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1),_c('remind-colleague',{attrs:{"icon":""},model:{value:(item.user),callback:function ($$v) {_vm.$set(item, "user", $$v)},expression:"item.user"}})]}}])})]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }