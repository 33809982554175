<template>
			<mw-dialog 
				title="Poll Details"
				v-model="dialog"
				cancel-btn
				large
			>
				<template v-slot:button>
					<v-btn icon @click="dialog = true">
						<v-icon>mdi-information</v-icon>
					</v-btn>
				</template>
				<template v-slot:dialog-body>
					<p>Poll status by individual users (whether polled individually or as part of a group) is available below. You can also delete polls and send reminders here.</p>
					<v-data-table :items="polls" :headers="headers">
					<template v-slot:item.group="{item}"> 
						<span v-if="item.group">{{groups[item.group].name}}</span>
                    </template>
					<template v-slot:item.user="{item}"> 
                        {{users[item.user].name}}
                    </template>
					<template v-slot:item.status="{item}"> 
						<v-chip color="success" v-if="item.status == 'submitted'">Submitted</v-chip>
						<v-chip color="secondary" v-if="item.status == 'published'">Outstanding</v-chip>
						<v-chip color="red" v-if="item.status == 'deleted'">Deleted</v-chip>
                    </template>
					<template v-slot:item.created_at="{item}"> 
                        {{mwutils.toReadableDate(item.created_at)}}
                    </template>
					<template v-slot:item.btn2="{item}"> 
						<v-btn icon @click="$emit('delete', item.id)" class="ml-2">
							<v-icon>mdi-trash-can</v-icon>
						</v-btn>
						<remind-colleague v-model="item.user" icon></remind-colleague>
					</template>
					</v-data-table>
				</template>
              
			</mw-dialog>
</template>
<script>
	import MwDialog from "@c/ui/MwDialog.vue";
	import RemindColleague from '@c/collaboration/RemindColleague.vue';
	export default {
		name: "EditPoll",
		props: {
			questionId: String,
			question: String,
			polls: [Object, Array]
		},
		data: () => {
			return {
				dialog: false,
				deleting: false
			};
		},
		computed: {
			users() {
				return this.$store.state.users.data
			},
			groups() {
				return this.$store.state.groups.data
			},
			headers() {
				return [
					{value: "created_at", text: "Created"},
					{value: "user", text: "Colleague"},
                    {value: "group", text: "Group"},
                    {value: "status", text: "Status"},
					{value: "btn2"}
				];
			}

		},
		methods: {
			submit(poll) {
				this.$store.dispatch("polls/patch", poll );
			}
		},
		watch: {
		},
		components: {
			MwDialog,     
			RemindColleague       
		},
	};
</script>
