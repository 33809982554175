<template>
	<v-sheet>
                <v-data-table
                    :items="items"
                    :search="search"
                    :headers="headers"
                    :items-per-page-options="[
                        {value: 5, title: 5},
                        {value: 10, title: 10},
                        {value: 20, title: 20}
                    ]"
                >
                    <template v-slot:item.submitted="{item}"> 
                        {{item.submitted}} of {{item.polls.length}} responded
                    </template>
                    <template v-slot:item.colleagues="{item}"> 
                        <span>{{item.groupNames}}<br/></span> 
                        <span>{{item.userNames}}<br/></span> 
                    </template>
                    <template v-slot:item.btn2="{item}"> 
                        <edit-poll v-bind="item" @delete="(v) => deleting = v"></edit-poll>
                    </template>
                </v-data-table>
                    <v-text-field placeholder="Search" v-model="search"></v-text-field>
                <are-you-sure
                text="Are you sure you want to delete this poll? "
                :value="deleting"
                @input="deletePoll()"
                @cancel="deleting = null"
                cancel-btn
                ></are-you-sure>

	</v-sheet>
</template>
<script>
    import EditPoll from "@c/team/EditPoll.vue";
    import AreYouSure from '../notices/AreYouSure.vue';
	export default {
		name: "PollListInner",
		data: () => {
			return {
				search: null,
                view: null,
				dialog: false,
				expanded: false,
				deleting: false
			};
		},
		computed: {
			groups() {
				return this.$store.state.groups.data;
			},
			users() {
				return this.$store.getters["users/respondents"];
			},
            current() {
                return this.$store.state.current;
            },
            section() {
                return this.$store.state.sections.data[this.current.section];
            },
            questions() {
                return this.section.questions
            },
            questionsWithPolls() {
                const self = this;
                return self.questions.map( question => {
                    return {question, polls: self.$store.getters["polls/byQuestion"](question)}
                }) 
            },
            items(){
                const self = this;
                let items = self.questionsWithPolls.filter( question => question.polls.length );
                items.forEach(item => {
                    item.submitted = item.polls.filter( poll => poll.status == "submitted" ).length;
                    item.groups = self.mwutils.uniqueArray(item.polls.filter( poll => poll.group ).map( poll => poll.group ) );
                    item.users = self.mwutils.uniqueArray(item.polls.filter( poll => !poll.group ).map( poll => poll.user ) );
                    item.userNames = item.users ? item.users.map( c => self.users[c].name).join(", ") : "";
                    item.groupNames = item.groups ? item.groups.map( c => self.groups[c].name).join(", ") : "";
                    item.questionText = self.$store.getters["questions/getQuestionTexts"](item.question).string
                });
                return items;
            },
			headers() {
				return [
					{value: "questionText", text: "Question"},
                    // {value: "groupNames", text: "Groups"},
                    {value: "colleagues", text: "Poll Users and Groups"},
                    {value: "submitted", text: "Status"},
					{value: "btn2"}
				];
			}
		},
		methods: {
            deletePoll() {
				this.$store.dispatch("polls/patch", {id: this.deleting, status: "deleted"}).then( () => {
					this.deleting = null;
				})
			},
		},
		watch: {
        },
		components: {
            EditPoll,
            AreYouSure
		},
	};
</script>
