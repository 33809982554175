<template>
	<mw-dialog v-model="dialog" title="Create Poll" large>
		<template v-slot:dialog-body>
			<v-sheet flat>
				<p>
					Create a poll to send one or more questions to your colleagues
					and see how they would answer. You'll see a count of answers
					shown in green underneath each answer option in the
					questionnaire; and individual poll results received are shown
					here.
				</p>
				<v-stepper elevation="0" v-model="step">
					<collaboration-wizard-header
						text="Questions"
						:value="step"
					></collaboration-wizard-header>
					<v-stepper-content step="1">
						<v-card elevation="0">
							<v-checkbox
								hide-details
								v-for="question in questionTexts"
								v-model="selected"
								multiple
								:key="question.id"
								:value="question.id"
								:label="question.text"
							>
							</v-checkbox>
							<v-card-actions class="mt-8 pb-0">
								<v-btn text @click="dialog = false">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn
									color="primary"
									@click="step = 2"
									:disabled="selected.length == 0"
								>
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>

					<v-stepper-content step="2">
						<v-card elevation="0">
							<p>To create your poll, please select groups or users below or create a new user by typing their name.</p>
							<h5>Groups</h5>
							<select-group v-model="selectedGroups"></select-group>
							<div class="mt-3 mb-8">
								<h5>Users</h5>
								<v-chip
									class="mt-2 mr-2"
									hide-details
									v-for="colleague in colleagues"
									close @click:close="removeColleague(colleague)"
									:key="colleague"
								>
								{{users[colleague].name}}
								</v-chip>
								<add-colleague
									@input="addColleague"
									clear-on-emit
									:hide="colleagues"
								></add-colleague>
							</div>
							<v-card-actions class="mt-8 mb-0">
								<v-btn text @click="dialog = false">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn
									color="primary"
									@click="step = 3"
									:disabled="colleagues.length == 0 && groups.length == 0"
								>
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
					<v-stepper-content step="3">
						<v-card elevation="0">
							<p>
								Adding polls for {{ selected.length }} questions for selected colleagues. 
							</p>
							<p>Please confirm by clicking on 'complete' to create your poll.</p>
							<v-checkbox
								v-model="notify"
								label="Notify participants?"
							></v-checkbox>
							<v-card-actions class="mt-8 mb-0">
								<v-btn text @click="dialog = false">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn color="primary" @click="complete">
									Complete
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
				</v-stepper>
			</v-sheet>
		</template>
	</mw-dialog>
</template>
<style scoped>
	.v-input--selection-controls {
		margin-top: 8px;
	}
	.v-stepper__content {
		padding-top: 0px;
	}
</style>
<script>
	import MwDialog from "@c/ui/MwDialog";
	import CollaborationWizardHeader from "@c/collaboration/CollaborationWizardHeader.vue";

	import AddColleague from "@c/collaboration/AddColleague.vue";
	import SelectGroup from "@c/collaboration/SelectGroup.vue";
	export default {
		name: "NewPoll",
		data: () => {
			return {
				dialog: null,
				step: 1,
				colleagues: [],
				selected: [],
				notify: true,
				selectedGroups: [],
			};
		},
		components: {
			AddColleague,
			MwDialog,
			CollaborationWizardHeader, 
			SelectGroup
		},
		computed: {
			current() {
				return this.$store.state.current;
			},
			section() {
				return this.$store.state.sections.data[this.current.section];
			},
			response() {
				return this.$store.state.sectionResponses.data[this.current.response];
			},
			visibleQuestions() {
				return this.mwsurveyutils.currentVisibleQuestions();
			},
			questionTexts() {
				const self = this;
				let questions =  self.visibleQuestions
				questions = questions.filter( q => !self.questions[q].hide_polling );
				questions =  questions.map((id) => {
					let text = self.$store.getters["questions/getQuestionTexts"](id);
					return {id, text: text.string};
				});
				return questions;
			},
			users() {
				return this.$store.state.users.data;
			},
			groups() {
				return this.$store.state.groups.data;
			},
			organisation() {
				return this.$store.state.organisation.data;
			},
			questions() {
				return this.$store.state.questions.data;
			},
			groupColleagues() {
				const self = this;
				let colleagues = self.selectedGroups.flatMap( group => {
					return self.groups[group].members.map( user => ([user, group]))
				})
				colleagues = Object.fromEntries(colleagues)
				return colleagues;
			}
		},
		methods: {
			addGroup( value ) {
				console.log( value )
			},
			addColleague(id) {
				this.colleagues.push(id);
			},
			removeColleague(id) {
				this.colleagues = this.colleagues.filter( a => a !== id )
			},
			clear() {
				this.selected = [];
				this.colleagues = [];
				this.step = 1;
			},
			addPollForUser(user, group){
				let poll = {
					questions: this.selected,
					user,
					group,
					notify: this.notify
				}
				this.$store.dispatch("polls/manyNew", poll);
			},
			complete() {
				const self = this;
				Object.keys(self.groupColleagues).forEach(user => {
						self.addPollForUser(user, self.groupColleagues[user])
				})
				self.colleagues.forEach((user) => {
					if( !self.groupColleagues[user] ){
						self.addPollForUser(user, false)
					}
				});
				self.dialog = false;
			}
		},
		watch: {
			dialog(v) {
				if (!v) {
					this.clear();
				}
			},
		},
		mounted() {
			const self = this;
			self.$root.$on("newPoll", (id) => {
				if( id ){
					self.selected.push(id);
				}
				self.dialog = true;
			});
		}
	}
</script>
