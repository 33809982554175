import Vue from "vue";
import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import VuexEasyFirestore from "vuex-easy-firestore";
Vue.use(Vuex);

import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import firebase from "firebase";
import auth from "./modules/auth.js";
import config from "./modules/config.js";

import organisations from "./modules/organisations.js";
import organisation from "./modules/organisation.js";
import groups from "./modules/groups.js";

import assessments from "./modules/assessments.js";
import assessment from "./modules/assessment.js";

import conference from "./modules/conference.js";
import conferences from "./modules/conferences.js";

import emails from "./modules/emails.js";
import polls from "./modules/polls.js";

import badges from "./modules/badges.js";
import themes from "./modules/themes";
import jobFunctions from "./modules/jobFunctions";
import services from "./modules/services";
import providers from "./modules/providers";
import categoryOptions from "./modules/categoryOptions";

import users from "./modules/users.js"
import sections from "./modules/sections.js";
import sectionResponses from "./modules/sectionResponses.js";
import staffSurveyResponses from "./modules/staffSurveyResponses.js";

import indicators from "./modules/indicators.js";
import questions from "./modules/questions.js";
import questionTemplates from "./modules/questionTemplates.js";

import content from "./modules/content.js"

import reports from "./modules/reports.js"
import visualisations from "./modules/visualisations.js"

import videoRoom from "./modules/videoRoom";


const vxf = VuexEasyFirestore(
	[
		assessment,
		assessments,
		auth,
		badges,
		content,
		categoryOptions,		
		conference,
		conferences,
		config,
		emails,
		groups,
		indicators, 
		jobFunctions, 
		organisation,
		organisations,
		polls,
		providers,
		questions, 
		questionTemplates,
		reports,
		sections,
		services,
		sectionResponses,
		staffSurveyResponses,
		themes,
		users,
		visualisations,
		videoRoom
	],
	{
		logging: true,
		FirebaseDependency: firebase,
	}
);

const mwstoreutils = {
	mutations: {
		RESET(state) {
			Vue.set(state, "data", {})
		}
	},
	actions: {
		
		fetchByIds: ({ dispatch }, ids) => {
			if (!ids) {
				return;
			}
			ids.forEach((id) => {
				dispatch("fetchById", id);
			});
		},
	},
};
const storeData = {
	actions,
	getters,
	mutations,
	state,
	plugins: [vxf],
	mixins: mwstoreutils,
};

const store = createStore(Vuex.Store, storeData);

export default store;
