var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('create-group'),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.groups,"items-per-page-options":[
				{value: 5, title: 5},
				{value: 10, title: 10},
				{value: 20, title: 20}
			]},scopedSlots:_vm._u([{key:"item.members",fn:function(ref){
			var item = ref.item;
return [_c('v-chip',{attrs:{"color":"success"}},[_vm._v(_vm._s(item.members.length))])]}},{key:"item.btn2",fn:function(ref){
			var item = ref.item;
return [_c('edit-group',{attrs:{"id":item.id}}),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.deleting = item.id}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"mt-5 lightest--background",attrs:{"color":"lightest"}},[_c('td',[_c('v-text-field',{attrs:{"placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('td',{attrs:{"colspan":"4"}})])]},proxy:true}])}),_c('are-you-sure',{attrs:{"text":"Are you sure you want to delete this group? The members of this group will not be affected.","value":_vm.deleting,"cancel-btn":""},on:{"input":function($event){return _vm.deleteGroup()},"cancel":function($event){_vm.deleting = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }