<template>
	<mw-dialog
		v-model="dialog"
		large
		submit-btn
		cancel-btn
		@submit="dialog = false"
		title="Logs"
	>
		<template v-slot:button>
			<v-btn v-if="icon" icon @click="dialog = true">
				<v-icon v-if="icon">mdi-check</v-icon>
			</v-btn>
			<v-list-item v-else @click="dialog = true">
				<v-list-item-title>
					Logs
				</v-list-item-title>
			</v-list-item>
		</template>
		<template v-slot:dialog-body v-if="response">
			<p>A record of all delegations, revocations and returns for this section is shown below</p>
			<v-simple-table>
				<thead>
					<tr>
						<th>Date</th>
						<th>Action</th>
						<th>User</th>
						<th>% Complete</th>
						<th>Details</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(log, i) in response.logs || []" :key="i">
						<td>{{mwutils.toReadableDate(log.at)}}</td>
						<td>{{log.action}}</td>
						<td>{{users[log.by] ? users[log.by].name : ''}}</td>
						<td>{{Math.floor(log.completion_rate)}}</td>
						<td>
							<span v-if="log.recommendation">
								<small v-if="log.recommendation.comments">
									{{log.recommendation.comments}} <br/>
								</small>
								<small v-if="log.recommendation.name">
									{{log.recommendation.name}} <br/>
								</small>
								<small v-if="log.recommendation.email">
									{{log.recommendation.email}} <br/>
								</small>
							</span>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
		</template>
	</mw-dialog>
</template>

<style lang="less">
	.button {
		cursor: pointer;
	}
</style>
<script>
	import MwDialog from "@c/ui/MwDialog";

	export default {
		name: "Logs",
		props: {
			id: {type: String}, // the ID of the response,
			icon: {type: Boolean, default: false}
		},
		data: () => {
			return {
				dialog: false
			};
		},
		computed: {
			response() {
				return this.$store.state.sectionResponses.data[this.id] || {};
			},
			users() {
				return this.$store.state.users.data;
			}
		},
		components: {
			MwDialog
		},
	};
</script>
