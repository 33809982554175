export default {
	firestorePath: "polls",
	firestoreRefType: "collection",
	moduleName: "polls",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {
		byQuestion(state, _, rootState) {
			return function(question) {
				return Object.values(state.data).filter((q) => {
					return (
						q.response == rootState.current.response && q.question == question
					);
				});
			};
		},
		myPolls(state, _, __, rootGetters) {
			if (!state.data) {
				return [];
			}
			var polls = Object.values(state.data);
			return polls.filter((a) => a.user == rootGetters["auth/id"]);
		},
		myActivePolls(_, getters) {
			let polls = getters.myPolls;
			return polls.filter((a) => a.status == "published");
		},
		len(state) {
			return Object.keys(state.data).length;
		}
	},
	mutations: {},
	actions: {
		myPolls({dispatch, rootGetters, state}) {
			let user = rootGetters["auth/id"];
			dispatch("fetchAndAdd", {
				clauses: {
					limit: -1,
					where: [["user", "==", user]]
				}
			}).then(() => {
				let sections = Object.values(state.data).map((s) => s.section);
				dispatch("sections/fetchByIds", sections, {root: true});
				let questions = Object.values(state.data).map((s) => s.question);
				dispatch("questions/fetchByIds", questions, {root: true});
			});
		},
		allPolls({dispatch, rootGetters}) {
			if (!rootGetters["assessment/id"]) {
				return;
			}
			return dispatch("openDBChannel", {
				clauses: {
					where: [["assessment", "==", rootGetters["assessment/id"]]]
				}
			});
		},
		byResponse({dispatch, rootGetters}, response) {
			if (!rootGetters["assessment/id"]) {
				return;
			}
			return dispatch("openDBChannel", {
				clauses: {
					limit: -1,
					where: [
						["response", "==", response],
						["assessment", "==", rootGetters["assessment/id"]]
					]
				}
			});
		},
		byUser({dispatch, rootGetters}, user ){
			if (!rootGetters["assessment/id"]) {
				return;
			}
			return dispatch("openDBChannel", {
				clauses: {
					limit: -1,
					where: [
						["user", "==", user],
						["assessment", "==", rootGetters["assessment/id"]]
					]
				}
			});
		},
		new({dispatch, rootState}, {question, user, notify, group}) {
			let response =
				rootState.sectionResponses.data[rootState.current.response];
			let poll = {
				response: response.id,
				assessment: response.assessment,
				section: response.section,
				service: response.service || null,
				question,
				group,
				user,
				status: "published"
			};
			return dispatch("insert", poll).then((id) => {
				if (notify) {
					dispatch("emails/poll", id, {root: true});
				}
				return id;
			});
		},
		manyNew({dispatch}, {questions, user, group, notify}) {
			questions.forEach((question, i) => {
				dispatch("new", {question, user, group, notify: notify ? i == 0 : false});
			});
		}
	}
};
