var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('p',{staticClass:"text-right"},[_c('mw-dialog',{attrs:{"small":"","submit-btn":"","title":_vm.$t('collaboration.add_colleague')},on:{"submit":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("collaboration.add_colleague"))+" ")])]},proxy:true},{key:"dialog-body",fn:function(){return [_c('add-colleague',{on:{"input":function($event){_vm.dialog = false}}})]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('import-users')],1),_c('v-data-table',{ref:"address-book",attrs:{"id":"address-book","search":_vm.search,"headers":_vm.headers,"items":_vm.items,"items-per-page-options":[
				{value: 5, title: 5},
				{value: 10, title: 10},
				{value: 20, title: 20}
			]},scopedSlots:_vm._u([{key:"item.btn2",fn:function(ref){
			var item = ref.item;
return [(_vm.assessment.id)?_c('collaborations-for-user',{model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}}):_vm._e(),_c('colleague-details',{attrs:{"icon":""},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}}),_c('remind-colleague',{attrs:{"icon":""},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}})]}},{key:"item.memberships",fn:function(ref){
			var item = ref.item;
return _vm._l((item.memberships),function(group){return _c('v-chip',{key:group,staticClass:"mr-2"},[_vm._v(_vm._s(group))])})}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"mt-5 lightest--background",attrs:{"color":"lightest"}},[_c('td',[_c('v-text-field',{attrs:{"placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('td',{attrs:{"colspan":"4"}})])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }